@mixin mobile() {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
.profile_pic {
  padding: 25px 0;
  background: linear-gradient(
    159deg,
    rgba(37, 37, 50, 0.98) 0,
    rgba(35, 35, 45, 0.98) 100%
  );
  text-align: center;
  position: relative;
}

.profile_pic img {
  margin: auto;
}
.about {
  background-color: #22222e73;
  padding: 16px 30px;
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.about::-webkit-scrollbar {
  width: 5px;
}

.about::-webkit-scrollbar-thumb {
  background-color: #3c3c54;
  border-radius: 50px;
}

.about ul li:not(:last-child) {
  border-bottom: 1px solid #424252;
}

.social_Icons {
  background: linear-gradient(
    159deg,
    rgba(37, 37, 50, 0.98) 0,
    rgba(35, 35, 45, 0.98) 100%
  );
}
._side_info {
  -webkit-transition: 0.55s ease-in-out;
  transition: 0.55s ease-in-out;
  @include mobile() {
    left: -290px;
    background-color: var(--theme-color);
    &.active {
      left: 0;
      ._skills_toggle_btn {
        right: 0;
        background: transparent;
        width: 55px;
      }
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        background-color: rgba(25, 25, 35, 0.5);
        z-index: -1;
      }
    }
  }
}
._skills_toggle_btn {
  width: 80px;
  height: 70px;
  position: absolute;
  right: -80px;
  // background: var(--theme-gradient-sec);
  z-index: 1;
  // display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.55s ease-in-out;
  transition: 0.55s ease-in-out;
  display: none;
  @include mobile() {
    display: flex;
  }
  span {
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #8c8c8e;
    position: relative;
    &::after,
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      background-color: inherit;
      border-radius: 50%;
    }
    &::before {
      top: -8px;
    }
    &::after {
      bottom: -8px;
    }
  }
}
