@mixin desktop() {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
.layout {
  @include desktop() {
    padding-top: 86px;
  }
  ._mob_header {
    height: 70px;
    width: 100%;
    background: var(--theme-gradient-sec);
    top: 16px;
    position: fixed;
    z-index: 1;
  }
}
