@mixin modalAnimation() {
  animation: popup 0.5s ease-in-out forwards;
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(#191923, 0.7);
  top: 0;
  left: 0;
  z-index: 99999;
  @include modalAnimation();
  .image {
    // background: url(../../assets/images/Projects/Kontrax.png) no-repeat,
    //   linear-gradient(to bottom, rgba(25, 25, 35, 0.9), rgba(32, 32, 42, 0.9)),
    //   url(../../assets/images/Projects/Kontrax.png) no-repeat;
    // background: var(--background);
    background-size: 97%, cover, cover !important;
    background-position: center !important;
    // background-repeat: no-repeat;
  }
  ._scroll {
    &::-webkit-scrollbar {
      width: 3px;
      background-color: #2d2f3e;
      // margin: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ffc107;
    }
  }
}
