.project_list {
  .project_card {
    overflow: hidden;
    position: relative;
    z-index: 0;
    cursor: pointer;
    & * {
      pointer-events: none;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      transition: background-color 0.3s linear;
    }
    &:hover {
      &::after {
        background-color: transparent;
      }
      img {
        transform: scale(1.2);
      }
      .about_project {
        bottom: 0;
      }
    }
    img {
      transition: transform 0.3s linear;
    }
    .about_project {
      pointer-events: none;
      position: absolute;
      // bottom: calc(100% - fit-content);
      bottom: -100%;
      left: 0;
      right: 0;
      height: fit-content;
      z-index: 1;
      // background-color: black;
      transition: bottom 0.3s linear;
    }
  }
}
