$twitter: #00aced;
$linkedin: #007bb6;
$facebook: #3b5998;
$instagram: #bc2a8d;
._common_svg {
  width: 18px;
  height: 18px;
  path {
    fill: #7a7a7a;
    transition: all 0.2s linear;
  }
}
._git_icon {
  @extend ._common_svg;
  &:hover {
    path {
      fill: #3e2c00;
    }
  }
}
._twitter_icon {
  @extend ._common_svg;
  &:hover {
    path {
      fill: $twitter;
    }
  }
}
._linkedIn_icon {
  @extend ._common_svg;
  &:hover {
    path {
      fill: $linkedin;
    }
  }
}
._facebook_icon {
  @extend ._common_svg;
  &:hover {
    path {
      fill: $facebook;
    }
  }
}
._instagram_icon {
  @extend ._common_svg;
  &:hover {
    path {
      fill: $instagram;
    }
  }
}
