@mixin mobile() {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
.nav {
  height: calc(100vh - 32px);
  transition: 0.55s ease-in-out;
  width: 80px;
  position: fixed;
  @include mobile() {
    // height: fit-content;
    top: 16px;
    right: 0;
    z-index: 1;
    background-color: transparent;
    // background: var(--theme-gradient-sec);
  }
  &.active {
    width: 230px;
    transform: translateX(-150px);
    @include mobile() {
      transform: translateX(0px);
      // &::before {
      //   content: '';
      //   width: 100%;
      //   height: 100%;
      //   position: fixed;
      //   left: 0;
      //   right: 0;
      //   top: 0;
      //   background-color: rgba(25, 25, 35, 0.5);
      //   z-index: -1;
      // }
      // background: var(--theme-gradient-sec);
    }
    .active_url_view {
      @include mobile() {
        background: var(--theme-gradient-sec);
      }
      .active_anchor {
        visibility: hidden;
        opacity: 0;
      }
      .ul_menu {
        li {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
  .active_url_view {
    height: calc(100% - 70px);
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .active_anchor {
      display: block;
      width: fit-content;
      transform: rotate(90deg);
      position: absolute;
      left: calc(80px - 28px);
      top: 0;
      transform-origin: 0 0;
      padding-left: 32px;
      text-transform: uppercase;
      transition: 0.55s ease-in-out;
      @include mobile() {
        display: none;
      }
    }
    .ul_menu {
      li {
        transition: 0.55s ease-in-out;
        transform: translateX(60px);
        opacity: 0;
        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            transition-delay: #{0.05 * $i}s;
          }
        }
      }
    }
  }
}
