@mixin modalAnimation() {
  animation: popup 0.5s ease-in-out forwards;
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(#191923, 0.7);
  top: 0;
  left: 0;
  z-index: 99999;
  @include modalAnimation();
}
