.content{
    -webkit-transition: .55s ease-in-out;
    transition: .55s ease-in-out;
    overflow: hidden;
    height: calc(100vh - 118px);
}

.intro{
    background-image: url(./assets/images/bg_portfolio.webp);
    position: relative;
    z-index: 0;
}
.intro::after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, rgba(45, 45, 58, .9) 15%, rgba(45, 45, 58, .7) 50%, rgba(43, 43, 53, .7) 100%);
    z-index: 0;
}

@media only screen and (min-width:1024px){
    .active {
            -webkit-transform: translateX(-150px);
            transform: translateX(-150px);
        }
    .content{
        height: calc(100vh - 32px);
        flex-basis: calc(100% - 360px);
    }
}