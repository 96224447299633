@mixin mobile() {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
.hamburger_btn {
  background: linear-gradient(
    159deg,
    rgba(37, 37, 50, 0.98) 0,
    rgba(35, 35, 45, 0.98) 100%
  );
  width: 100%;
  height: 70px;
  @include mobile() {
    background: transparent;
  }
  button {
    width: 80px;
    height: 100%;
    position: relative;
    &.active {
      span {
        transform: rotate(45deg);
        &::before {
          transform: translate(0, 5px) rotate(-90deg);
        }
        &::after {
          transform: translate(0, -5px) rotate(-90deg);
        }
      }
    }
    span {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width: 15px;
      height: 3px;
      background-color: #8c8c8e;
      transition: 0.4s ease-in-out;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        background-color: #8c8c8e;
        top: -5px;
        transition: 0.4s ease-in-out;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        background-color: #8c8c8e;
        top: 5px;
        transition: 0.4s ease-in-out;
      }
    }
  }
}
